import {
    FaceControlBooleanConditions,
    FaceControlConditionTypes,
    FaceControlPercentConditions,
    FaceControlRegexpConditions
} from '@/includes/types/FaceControl.types'
import {
    AvatarCondition,
    DescriptionEnglishCondition,
    DescriptionHanCondition,
    DescriptionRegexCondition,
    DescriptionRtlCondition,
    DescriptionRussianCondition,
    FaceControlConditions,
    NameEnglishCondition,
    NameHanCondition,
    NameRegexCondition,
    NameRtlCondition,
    NameRussianCondition,
    RealNameCondition, ReferencesCondition,
    UsernameCondition
} from '@/includes/logic/FaceControl/FaceControlConditions'

import { ClassConstructor } from 'class-transformer'

export default class FaceControlConditionsFabric {
    static createCondition(type: FaceControlConditionTypes): ClassConstructor<FaceControlConditions> {
        switch (type) {
            case FaceControlBooleanConditions.Avatar:
                return AvatarCondition
            case FaceControlBooleanConditions.Username:
                return UsernameCondition
            case FaceControlBooleanConditions.Real_name:
                return RealNameCondition
            case FaceControlBooleanConditions.References:
                return ReferencesCondition
            case FaceControlPercentConditions.Name_english:
                return NameEnglishCondition
            case FaceControlPercentConditions.Name_russian:
                return NameRussianCondition
            case FaceControlPercentConditions.Name_rtl:
                return NameRtlCondition
            case FaceControlPercentConditions.Name_han:
                return NameHanCondition
            case FaceControlPercentConditions.Description_english:
                return DescriptionEnglishCondition
            case FaceControlPercentConditions.Description_russian:
                return DescriptionRussianCondition
            case FaceControlPercentConditions.Description_rtl:
                return DescriptionRtlCondition
            case FaceControlPercentConditions.Description_han:
                return DescriptionHanCondition
            case FaceControlRegexpConditions.Name_regex:
                return NameRegexCondition
            case FaceControlRegexpConditions.Description_regex:
                return DescriptionRegexCondition
            default:
                throw new Error('Unknown type provided')
        }
    }
}