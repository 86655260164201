import {
    FaceControlBooleanConditions,
    FaceControlConditionTypes,
    FaceControlPercentConditions, FaceControlRegexpConditions
} from '@/includes/types/FaceControl.types'

import { Exclude } from 'class-transformer'
import { snakeCase } from 'lodash'

export abstract class FaceControlConditions {
    type: FaceControlConditionTypes;

    @Exclude()
    title:string;

    @Exclude()
    abstract view: () => any

    protected constructor(type: FaceControlConditionTypes) {
        this.type = type
        this.title = `condition_${ snakeCase(type) }_title`
    }

    abstract validate()
}
export class BooleanCondition extends FaceControlConditions {
    view = () => import('@/components/FaceControl/ConditionViews/BooleanConditionView.vue')

    value = false

    protected constructor(type: FaceControlBooleanConditions) {
        super(type)
    }

    validate() {
        return true
    }
}
export class PercentCondition extends FaceControlConditions {
    view = () => import('@/components/FaceControl/ConditionViews/PercentConditionView.vue')

    mode: "Greater" | "Less" = "Greater"

    percent = 50

    protected constructor(type: FaceControlPercentConditions) {
        super(type)
    }

    validate() {
        return this.percent > 0;
    }
}
export class RegexCondition extends FaceControlConditions {

    pattern = ''

    invert = false

    view = () => import('@/components/FaceControl/ConditionViews/RegexConditionView.vue')

    protected constructor(type: FaceControlRegexpConditions) {
        super(type)
    }

    validate() {
        return this.pattern !== '';
    }
}

export class AvatarCondition extends BooleanCondition {
    constructor() {
        super(FaceControlBooleanConditions.Avatar);
    }
}
export class UsernameCondition extends BooleanCondition {
    constructor() {
        super(FaceControlBooleanConditions.Username);
    }
}
export class RealNameCondition extends BooleanCondition {
    constructor() {
        super(FaceControlBooleanConditions.Real_name);
    }
}
export class ReferencesCondition extends BooleanCondition {
    constructor() {
        super(FaceControlBooleanConditions.References);
    }
}
export class NameEnglishCondition extends PercentCondition {
    constructor() {
        super(FaceControlPercentConditions.Name_english);
    }
}
export class NameRussianCondition extends PercentCondition {
    constructor() {
        super(FaceControlPercentConditions.Name_russian);
    }
}
export class NameRtlCondition extends PercentCondition {
    constructor() {
        super(FaceControlPercentConditions.Name_rtl);
    }
}
export class NameHanCondition extends PercentCondition {
    constructor() {
        super(FaceControlPercentConditions.Name_han);
    }
}
export class DescriptionEnglishCondition extends PercentCondition {
    constructor() {
        super(FaceControlPercentConditions.Description_english);
    }
}
export class DescriptionRussianCondition extends PercentCondition {
    constructor() {
        super(FaceControlPercentConditions.Description_russian);
    }
}
export class DescriptionRtlCondition extends PercentCondition {
    constructor() {
        super(FaceControlPercentConditions.Description_rtl);
    }
}
export class DescriptionHanCondition extends PercentCondition {
    constructor() {
        super(FaceControlPercentConditions.Description_han);
    }
}
export class NameRegexCondition extends RegexCondition {
    constructor() {
        super(FaceControlRegexpConditions.Name_regex);
    }
}
export class DescriptionRegexCondition extends RegexCondition {
    constructor() {
        super(FaceControlRegexpConditions.Description_regex);
    }
}
