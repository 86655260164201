export enum FaceControlMode {
    Accept = 'Accept',
    Reject = 'Reject'
}

export enum FaceControlBooleanConditions {
    Avatar = 'avatar',
    Username = 'username',
    Real_name = 'real_name',
    References = 'references'
}

export enum FaceControlPercentConditions {
    Name_english = 'name_english',
    Name_russian = 'name_russian',
    Name_rtl = 'name_rtl',
    Name_han = 'name_han',
    Description_english = 'description_english',
    Description_russian = 'description_russian',
    Description_rtl = 'description_rtl',
    Description_han = 'description_han',
}

export enum FaceControlRegexpConditions {
    Name_regex = 'name_regex',
    Description_regex = 'description_regex',
}

export type FaceControlConditionTypes = FaceControlBooleanConditions | FaceControlPercentConditions | FaceControlRegexpConditions

export type FaceControlBoolean = {
    type: FaceControlBooleanConditions
    value: boolean
}

export type FaceControlPercent = {
    type: FaceControlPercentConditions
    percent: number
}

export type FaceControlRegex = {
    type: FaceControlRegexpConditions
    pattern: string
    invert: boolean
}

export type PossibleConditions = FaceControlBoolean | FaceControlPercent | FaceControlRegex

export type FaceControlStructure = {
    mode: FaceControlMode
    active: boolean
    conditions: Array<Array<PossibleConditions>>
}